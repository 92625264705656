import request from "@utils/request";

//获取优惠券列表
export const getCouponList =()=>{
    return request({
        url:'index/goods/getCouponList',
        method:'get'
    })
}
//领取优惠券
export const getCoupon = (params)=>{
    return request({
        url:'index/shop/getCoupon',
        method:'post',
        params
    })
}
//我的优惠券
export const myCoupon = () =>{
    return request({
        url:'index/center/myCoupon',
        method:'get',

    })
}

