<template>
  <div class="kc-contain">
    <h5>
      <img src="@assets/img/profile/icon-back.png"  @click="$router.go(-1)"/>
      迅鸟商城
    </h5>
    <div class="view-type">
      <div :class=" viewType == 1? 'view-type-item view-type-item-active':'view-type-item'" @click="viewType = 1">
        尊贵VIP
      </div>
      <div :class=" viewType == 2? 'view-type-item view-type-item-active':'view-type-item'" @click="viewType = 2" >
        云存储空间
      </div>
      <div :class=" viewType == 3? 'view-type-item view-type-item-active':'view-type-item'" @click="viewType = 3" >
        下载流量
      </div>
      <div :class=" viewType == 4? 'view-type-item view-type-item-active':'view-type-item'" @click="viewType = 4" >
        优惠券
      </div>
      <div  :class=" viewType == 1? 'line _vip':viewType == 2?'line _space':viewType == 3?'line _flow':viewType == 4?'line _coupon':'' "></div>
<!--      :style=" "-->
    </div>
    <div class="kc_body-contain">
<!--      <h6>尊贵VIP</h6>-->
      <div class="kc-body-content">
        <!--      Vip会员-->
        <div v-show="viewType == 1" class="vips" >
          <div
            :class="vip == index? 'vip-item-active':'vip-item'"
            :style=" vip == index? { background: bkColor,boxShadow: bkboxShadow} :''"
            v-for="(item,index) in vipGoodsList"
            :key="index"
            @click="handelVip(index,item)"
          >
            <h4 :style="index | priceColor "> {{item.title}} </h4>
            <div class="price"  >
              <i :style="index | price_i"></i>
              <span :style="index | priceColor" >￥{{item.price}}/月</span>
              <i :style="index | price_i" ></i>
            </div>
            <div class="functions">
              <div class="function-item">
                <img :src=" index == vip ? require('@assets/img/profile/icon-checked1.png') : require('@assets/img/profile/icon-checked.png')" />
                <span>赠送{{ item.storage_size +'GB' }}云存储空间</span>
              </div>
              <div class="function-item">
                <img :src=" index == vip ? require('@assets/img/profile/icon-checked1.png') : require('@assets/img/profile/icon-checked.png')" />
                <span>赠送{{item.flow_size}}GB流量</span>
              </div>
              <div class="function-item">
                <img :src=" index == vip ? require('@assets/img/profile/icon-checked1.png') : require('@assets/img/profile/icon-checked.png')" />
                <span>购买空间、流量享{{item.vip_discount}}折优惠</span>
              </div>
              <div class="function-item">
                <img :src=" index == vip ? require('@assets/img/profile/icon-checked1.png') : require('@assets/img/profile/icon-checked.png')" />
                <span>上传、下载永不限速</span>
              </div>
              <div class="function-item">
                <img :src=" index == vip ? require('@assets/img/profile/icon-checked1.png') : require('@assets/img/profile/icon-checked.png')" />
                <span>解锁发送文件定价功能</span>
              </div>
              <div class="function-item" style="margin-bottom: 0">
                <img :src=" index == vip ? require('@assets/img/profile/icon-checked1.png') : require('@assets/img/profile/icon-checked.png')" />
                <span>定制专属传输背景</span>
                <img class="help" @mouseout="helpTips = false" @mousemove="index == vip ? helpTips = true:''" :src=" index == vip ? require('@assets/img/profile/icon-help.png') : require('@assets/img/profile/icon-help1.png')" alt="">
              </div>
              <div class="function-tips" v-show="helpTips && index == vip" >
                其他用户访问您分享的链接可展示您设置的专属背景
              </div>
            </div>

          </div>
        </div>
        <!--      云存储空间和流量-->
        <div v-show="viewType == 2 || viewType == 3" class="storage-space">
          <div
              :class=" space == index ? 'storage-space-itemBox':'storage-space-itemBox-action'"
               v-for="(item,index) in spaceAndFlowGoodsList"

               @click="handelSpace(index)" :key="index"
          >
<!--            切换流量和空间背景图-->
            <div
                :class=" space == index ? 'storage-space-item':'storage-space-item-action'"
                :style="{
                  backgroundImage: viewType == 2 ?
                  space == index ?'url(' + spaceBk1 + ')' :'url(' + spaceBk2 + ')':
                  space == index ? 'url(' + flowBk1 + ')':'url(' + flowBk2 + ')'
                }"
            >
              <span class="title"> {{viewType == 2 ?'存储空间':'下载流量'}} </span>
              <div class="spaceBox">
                <div class="space">{{item.size}}GB</div>
                <span class="font">永久有效</span>
              </div>
            </div>
            <div :class="space == index?'storage-space-money':'storage-space-money-action'">￥{{item.price}}</div>
          </div>
          <!--          自定义-->
          <div :class=" space == -1 ? 'storage-space-itemBox':'storage-space-itemBox-action'"  @click="handelSpace(-1)" >
            <div :class=" space == -1 ? 'storage-space-item':'storage-space-item-action'"
                 :style="{
                  backgroundImage: viewType == 2 ?
                  space == -1 ?'url(' + spaceBk1 + ')' :'url(' + spaceBk2 + ')':
                  space == -1 ? 'url(' + flowBk1 + ')':'url(' + flowBk2 + ')'
                }"
            >
              <span class="title">{{viewType == 2 ?'存储空间':'下载流量'}}</span>
              <div class="spaceBox">
                <span class="space"><input type="text" v-model="spaceNum"  @input="handelSpace(-1)" class="spaceInput" placeholder="自定义数量">GB</span>
                <span class="font"><p>永久有效</p></span>
              </div>
            </div>
            <div :class="space == -1?'storage-space-money':'storage-space-money-action'">￥{{spaceAndFlowGoodsCustom.price}}</div>

          </div>
        </div>
        <!--     优惠券 -->
        <div v-show="viewType == 4 " class="coupon-content">
          <div  class="coupon-itemBox" v-if="couponList.length">
            <div class="coupon-item" v-for="(item,index) in couponList" :key="index">
              <div class="discounts" :style="{backgroundImage: time >=item.expiry_time ?   'url('+ coupons_bg_expire+')' : 'url('+ coupons_bg+')'}">
                <span>{{ item.discount * 10}}</span><i>折</i>
              </div>
              <div class="coupon-item-content">
                <h5>折扣券、所有商品{{item.discount * 10}}折</h5>
                <p>有效期{{item.expiry_time}}</p>
              </div>
              <div class="btn" :style="{ background:time >=item.expiry_time ?'linear-gradient(270deg, #E8E8E8 0%, #D2D2D2 100%)':'background: linear-gradient(270deg, #63A4FF 0%, #175BFF 100%);'  }">
                <span @click="receiveCoupon(item)">{{item.get_status == 1?'已领取':'立即领取'}}</span>
                <img v-show="time >=item.expiry_time" src="@assets/img/profile/icon-expire.png"/>
              </div>
            </div>
          </div>
            <div class="notBox" v-else>
              <img src="@assets/img/not_Coupon.png">
              <span>暂无优惠券</span>
            </div>
        </div>



        <!--   底部支付操作 -->
        <div v-show="viewType != 4 " class="paymentBox">
          <div class="payment-left">
            <div class="payment-handel">
              <div v-if="viewType == 1" class="time">
                <span>购买期限：</span>
                <div class="handel">
                  <img src="@assets/img/index/icon-reduce.png" @click="reduce" alt="">
                  <span v-if="isVipInputState" @click="isVipInputState = false;isVipInput = period" style="cursor: pointer">{{period}}</span>
                  <input v-else v-model="isVipInput" @input="getVipData" @blur="isVipInputState = true" type="text">
                  <img src="@assets/img/index/icon-add.png" @click="increase" alt="">
                </div>
              </div>

              <div class="dropdown">
                <span>购买优惠：</span>
                <div v-if="viewType != 1 && userInfo.vip_status == 1" class="member">
                  <img :src="offer_type==1?selected:unselected"  @click="handelSpaceDiscountType(1)"   />
                  <span>会员{{memberDiscount}}折</span>
                </div>
                <div class="coupon">
                  <img v-show = "viewType !=  1" :src="offer_type==2?selected:unselected" @click="handelSpaceDiscountType(2)" />
                  <span v-show = "viewType != 1" >优惠券</span>
                  <el-select no-data-text="无可用优惠券" v-model="offer" placeholder="请选择" @change="handelDiscount" clearable size="mini">
                    <el-option
                        v-for="item in MemberCouponList"
                        :key="item.value"
                        :label="item.discount + '折优惠券'"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="payment-info">
              <div :style="{width:pay_type == 'dhm' ? '100%':'50%'}" class="right">
                <div class="btnBox">
                  <div class="btnType">
                    <div :class=" pay_type == 'wx'? 'btn wx-action':'btn wx'" @click="pay_type = 'wx'">
                      微信支付
                      <img  v-if="pay_type == 'wx'" src="@assets/img/icon-pay_type-checked.png" alt="">
                    </div>
                    <div :class=" pay_type == 'zfb'? 'btn zfb-action':'btn zfb' " @click="pay_type = 'zfb'">
                      支付宝支付
                      <img v-if="pay_type == 'zfb'" src="@assets/img/icon-pay_type-checked.png" alt="">
                    </div>
                    <div v-show="viewType == 1" :class=" pay_type == 'dhm'? 'btn dhm-action':'btn dhm' " @click="pay_type = 'dhm'">
                      使用兑换码
                      <img v-if="pay_type == 'dhm'" src="@assets/img/icon-pay_type-checked.png" alt="">
                    </div>
                  </div>
                  <div class="exCodeBox">
                    <span v-show="pay_type != 'dhm'">选择支付方式</span>
                    <div class="code" v-show="pay_type == 'dhm' && viewType == 1">
                      <span>兑换码</span>
                      <div class="inputBox">
                          <input v-model="exCode" placeholder="如果有兑换码可在这输入" type="text">
                        <div class="changeExCode" @click="handelExCode">
                          <img src="@assets/img/profile/icon-exCode.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info" v-show="pay_type != 'dhm'">
<!--                  <span><p>支付金额：</p></span>-->
                  <span class="moneyBox">
                    <p>{{pay_type == 'wx'?'微信':'支付宝'}}</p>
                    <p class="money" style="margin-right: 8px">
                      ￥{{money}}
                    </p>
                    <p class="discount" v-show="couponsMoney != '0.00'">
                      已优惠 {{couponsMoney}}元
                    </p>
                  </span>
                </div>
              </div>
              <div class="left" v-if="pay_type != 'dhm'">
                <div v-show="showQr" class="qrBox">
                  <img :src="payObj.pay_img" alt="">
                </div>
                <div  class="btn" @click="getPay">
                  {{showQr?'刷新二维码':'提交订单'}}
                </div>
              </div>
            </div>
          </div>
          <div class="payment-right">
            <div class="title">购买提示：</div>
            <div class="tips" v-html="htmlInfo"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import QRCode from 'qrcode'
import {
  checkOrderPay,
  exchange,
  getBuyRecharge,
  getBuyVip,
  getGoodsList,
  sendBuyRecharge,
  sendBuyVip
} from "@assets/api/shop";
import {mapState} from "vuex";
import {getCoupon, getCouponList} from "@assets/api/coupons";
import {getUserInfo} from "@assets/api/user/user";
export default {
  name: "",
  components: {},

  data() {
    return {
      selected:require('@assets/img/icon-selected.png'),
      unselected:require('@assets/img/icon-unselected.png'),
      spaceBk1:require('@assets/img/profile/shop-spaceAndflowBg1.png'),
      spaceBk2:require('@assets/img/profile/shop-spaceAndflowBg2.png'),
      flowBk1:require('@assets/img/profile/shop-spaceAndflowBg3.png'),
      flowBk2:require('@assets/img/profile/shop-spaceAndflowBg4.png'),
      viewType:1,//navBar
      prodVisible:false,//商品弹窗显示
      prod_type:'1',
      pay_type:'wx', //支付方式
      QRImgUrl:'',
      // goodsObj:{},//商城信息
      // 购买vip
      isVipInputState:true,
      isVipInput:0,
      vipGoodsList:[],//vip商品
      spaceAndFlowGoodsList:[],//空间和流量数据
      spaceAndFlowGoodsCustom:{},//空间和流量自定义数据
      MemberCouponList:[],//优惠券宿主
      memberDiscount:'',//会员折扣
      bkColor:'',//背景颜色
      bkboxShadow:'',//背景投影
      money:0,//vip支付金额
      couponsMoney:0,//优惠金额
      vip:0,//背景样式下标
      period:1,//期限
      offer:'',//优惠券
      payObj:{},//支付链接对象

      //购买云存储空间
      space:-2,//选中的下标
      spaceNum:'', //自定义空间
      offer_type:'', //优惠类型
      space_offer:'', //选择的优惠券

      //支付二维码
      showQr:false,
      timer:'',//定时器
      shopInfo:'',

      coupons_bg:require('@assets/img/profile/coupons_bg.png'),
      coupons_bg_expire:require('@assets/img/profile/coupons_bg-expire.png'),
      couponList:[],
      time:'',//当前时间
      htmlInfo:'',//富文本
      helpTips:false,//会员问号图标
      exCode:'',//兑换吗
    };
  },
  watch:{
    vip:{
      handler(newVal){
        if(newVal == 0){
          this.bkColor = '#72C3C3'
          this.bkboxShadow = '0 10px 10px 0px rgb(12 115 115 / 20%)'
        }
        if(newVal == 1){
          this.bkColor = '#38A2F7'
          this.bkboxShadow = '0 10px 10px 0px rgb(56 162 247 / 20%)'
        }
        if(newVal == 2){
          this.bkColor = '#333333'
          this.bkboxShadow = '0 10px 10px 0px rgb(30 21 17 / 20%)'
        }
      },
      deep:true,
      immediate:true
    },
    pay_type(){
      this.showQr = false
      clearInterval(this.timer)
    },
    viewType(newVal){
      this.handelNavBar(newVal)
      //用户获取优惠方式
      this.handelDiscountType()
    },
    shopType(newVal){
      console.log('newVal',newVal)
      this.viewType = newVal
      this.$store.state.shopType = newVal
      this.handelNavBar(newVal)
      //用户获取优惠方式
      this.handelDiscountType()
    }
  },
  filters:{
    price_i(val){
      if(val == 0){
        return {background: '#72C3C3'}
      }
      if(val == 1){
        return { background: '#38A2F7'}
      }
      if(val == 2){
        return  { background: '#333333' }
      }
    },
    priceColor(val){
      if(val == 0){
        return {color:'#72C3C3'}
      }
      if(val == 1){
        return { color: '#38A2F7'}
      }
      if(val == 2){
        return {color: '#333333'}
      }
    },

  },
  computed: {
    ...mapState({shopType:'shopType',userInfo:'userInfo'})

  },

  created() {
    this.getBuyVipData()
    this.getCouponListData()
    this.getNowDate()
    window.clearInterval(this.timer)
    if(this.$route.params.index){
      this.viewType = this.$route.params.index
    }

  },
  beforeDestroy() {
    window.clearInterval(this.timer)
  },
  methods: {
    //用户获取优惠方式
    handelDiscountType(){
      if( this.viewType == 1 || this.viewType == 4) return false
      if(this.userInfo.vip_status == 1){
        this.offer_type = 1
      }else{
        this.offer_type = 2
      }

    },
    //用户信息
    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          let userInfo = res.data
          this.$store.commit('getUserInfo',userInfo)
        }
      })
    },
    //切换NabBar
   async handelNavBar(index){
      this.prod_type = index
      // this.shopType = index
      this.$store.state.shopType = index
      clearInterval(this.timer)
      //vip页面数据
      if(index == 1){
        this.getBuyVipData()
        this.offer = ''
        this.period = 1
        this.handelVip(0,this.vipGoodsList[0])
        this.money = 0
        this.couponsMoney = 0
        this.pay_type = 'wx'
      }
      if(index == 2){
     await  this.getBuyRechargeData(1)
        this.space_offer = ''
        this.offer = ''
        this.spaceNum = ''
        this.money = 0
        this.couponsMoney = 0
        this.handelSpace(0)
        this.pay_type = 'wx'

      }
      if(index == 3){
       await this.getBuyRechargeData(2)
        this.space_offer = ''
        this.offer = ''
        this.spaceNum = ''
        this.money = 0
        this.couponsMoney = 0
        this.handelSpace(0)
        this.pay_type = 'wx'
      }
      if(index == 4){
        this.getCouponListData()
      }
    },
    //获取购买vip商品页数据
    getBuyVipData(){
      getBuyVip().then(res=>{
        this.vipGoodsList = res.data.goods_list
        this.MemberCouponList = res.data.member_coupon_list
        this.handelVip(0,res.data.goods_list[0])

      })
    },
    //获取空间和流量页面数据
   async  getBuyRechargeData(type){
      let params = {
        goods_type:type
      }
      await  getBuyRecharge(params).then(  res=>{
        this.spaceAndFlowObj = res.data
        this.spaceAndFlowGoodsList = res.data.goods_list
        this.spaceAndFlowGoodsCustom = res.data.single_goods
        this.MemberCouponList = res.data.member_coupon_list
        this.memberDiscount = res.data.member_discount
        this.htmlInfo = res.data.content
      })
    },
    //获取支付弹窗
    getPay(){
      let params = {}
      if(this.prod_type == 1){
        params.goods_id = this.vipGoodsList[this.vip].id
        params.num = this.period
        params.pay_type = this.pay_type == 'wx'?1:2
        params.coupon_id = this.offer
        sendBuyVip(params).then(res=>{
          if(res.code == 1){
            this.payObj = res.data
            this.showQr = true
            this.verificationIsPay(this.payObj)

          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }
      if(this.prod_type == 2 || this.prod_type == 3){

        if(this.space == -1 && this.spaceNum == ''){
          return this.$Message.error('请填写数量')
        }

        params.goods_id = this.space == -1?this.spaceAndFlowGoodsCustom.id:this.spaceAndFlowGoodsList[this.space].id
        params.num  = this.space == -1 ?  this.spaceNum :1
        params.pay_type = this.pay_type == 'wx'?1:2
        params.discount_type = this.offer_type
        params.coupon_id = this.offer
        sendBuyRecharge(params).then(res=>{
          if(res.code == 1){
            this.payObj = res.data
            this.showQr = true
            this.verificationIsPay(this.payObj)
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }
    },
    //验证是否支付
    verificationIsPay(data){
      let params = {
        order_number:data.order_number
      }
      this.timer = setInterval(()=>{
        checkOrderPay(params).then(res=>{
          if(res.code == 1){
            this.prodVisible = false
            this.$Message.success(`${res.msg}`)
            this.getUser()
            this.viewType = 1
            this.vip = 0
            this.offer = ''
            this.isVipInputState = true
            this.isVipInput = 1
            this.period = 1
            this.handelVip(0,this.vipGoodsList[0])
            clearInterval(this.timer)
          }else{
            // this.$Message.error(`${res.msg}`)
          }
        })

      },2000)
    },


    //选择vip计算金额
    handelVip(index,data){
      this.htmlInfo = data.content
      this.vip = index
      if(index == -1) return false
      let offer = this.MemberCouponList.find(item=>item.id == this.offer) || 10
      let obj = this.countMoney(this.vipGoodsList[index].price,offer.discount,this.period)
      this.money = obj.showMoney
      this.couponsMoney = obj.couponsMoney
    },
    //计算金额
    countMoney(price,discount = '',num){
      //计算折扣
      let offer = discount? discount / 10 : 1
      //计算折扣金额
      let money  =   ((price  * num) * offer)
      //四舍五入
      let showMoney = money.toFixed(2)
      //总价
      let total = (price * num).toFixed(2)
      //计算优惠金额
      let couponsMoney = ( total - showMoney).toFixed(2)
      //获取新的支付二维码
      // this.getVipPay()
      clearInterval(this.timer)
      this.showQr = false
      return {
        showMoney,
        couponsMoney
      }
    },
    //vip数量减
    reduce(){
      if(this.period==1){
        return false;
      }
      this.period--
      this.isVipInput--
      let offer = this.MemberCouponList.find(item=>item.id == this.offer) || 10
      let obj = this.countMoney(this.vipGoodsList[this.vip].price,offer.discount,this.period)
      this.money = obj.showMoney
      this.couponsMoney = obj.couponsMoney
    },
    //vip数量修改
    getVipData(){
      this.isVipInput=this.isVipInput.replace(/^(0+)|[^\d]+/g,'')
      if(this.isVipInput == ''){
        this.isVipInput = 1
        this.period = 1
        let offer = this.MemberCouponList.find(item=>item.id == this.offer) || 10
        let obj = this.countMoney(this.vipGoodsList[this.vip].price,offer.discount,this.period)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
        return false
      }
      this.period = this.isVipInput
      let offer = this.MemberCouponList.find(item=>item.id == this.offer) || 10
      let obj = this.countMoney(this.vipGoodsList[this.vip].price,offer.discount,this.period)
      this.money = obj.showMoney
      this.couponsMoney = obj.couponsMoney
    },
    //vip数量加
    increase(){
      this.period++
      this.isVipInput++
      let offer = this.MemberCouponList.find(item=>item.id == this.offer) || 10
      let obj = this.countMoney(this.vipGoodsList[this.vip].price,offer.discount,this.period)
      this.money = obj.showMoney
      this.couponsMoney = obj.couponsMoney
    },
    //使用优惠券
    handelDiscount(){
     //选择优惠券后 默认使用优惠券
      this.offer_type = 2
     if(this.viewType == 1){
       let offer = this.MemberCouponList.find(item=>item.id == this.offer) || 10
       let obj = this.countMoney(this.vipGoodsList[this.vip].price,offer.discount,this.period)
       this.money = obj.showMoney
       this.couponsMoney = obj.couponsMoney
     }
     if( (this.viewType == 2 || this.viewType == 3) && this.offer_type == 2){
       this.handelSpaceDiscount()
     }

    },
    //选择空间界面优惠券
    handelSpaceDiscount(){
      let type =  this.offer_type
      let num = this.space != -1 ?1:this.spaceNum
      if(type == 1){
        // this.spaceAndFlowObj.goods_list[index].price
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let price = this.space == -1 ? this.spaceAndFlowGoodsCustom.price :this.spaceAndFlowGoodsList[this.space].price
        let obj = this.countMoney(price,offer.discount,num)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
      }
      if (type == 2){
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let price = this.space == -1 ? this.spaceAndFlowGoodsCustom.price :this.spaceAndFlowGoodsList[this.space].price
        let obj = this.countMoney(price,offer.discount,num)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
      }
    },
    //云存储空间选择商品
    handelSpace(index){
      // if(index == -2) return false
      if(this.spaceNum === ''){
        this.money = 0
      }

      this.spaceNum=this.spaceNum.replace(/^(0+)|[^\d]+/g,'')
      this.space = index
      let num = this.space != -1 ?1:this.spaceNum

      if(this.offer_type == 0){
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let price = this.space >=0?this.spaceAndFlowGoodsList[index].price:this.spaceAndFlowGoodsCustom.price
        let obj = this.countMoney(price,offer.discount,num )
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
        return false
      }
      if(index != -1){
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let obj = this.countMoney(this.spaceAndFlowGoodsList[index].price,offer.discount,num)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
      }else{
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let obj = this.countMoney(this.spaceAndFlowGoodsCustom.price,offer.discount,this.spaceNum)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
      }
    },
    //空间优惠方式
    handelSpaceDiscountType(type){
      this.offer_type = type
      let num = this.space != -1 ?1:this.spaceNum
      if(type == 1){
        // this.spaceAndFlowObj.goods_list[index].price
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let price = this.space == -1 ? this.spaceAndFlowGoodsCustom.price :this.spaceAndFlowGoodsList[this.space].price
        let obj = this.countMoney(price,offer.discount,num)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
      }else{
        let price = this.space == -1 ? this.spaceAndFlowGoodsCustom.price :this.spaceAndFlowGoodsList[this.space].price
        let offer =  this.offer_type == 1 ?  {discount:this.memberDiscount}  : this.MemberCouponList.find(item=>item.id == this.offer ) || 10
        let obj = this.countMoney(price,offer.discount,num)
        this.money = obj.showMoney
        this.couponsMoney = obj.couponsMoney
      }
    },
    //获取优惠券列表
    getCouponListData(){
      let str = ''
      getCouponList().then(res=>{
        if(res.code == 1){
          this.couponList = res.data.list
          this.couponList.forEach(item=>{
            str =  item.expiry_time.split(' ')
            this.$set(item,'expiry_time',str[0])
          })
          console.log('res',res)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    //领取优惠券
    receiveCoupon(data){
      console.log(data)
      if(data.get_status != 1 && this.time<=data.expiry_time){
        let params = {
          coupon_id:data.id
        }
        console.log(data)
        getCoupon(params).then(res=>{
          console.log('res',res)
          if(res.code == 1){
            this.$Message.success(`${res.msg}`)
            this.getCouponListData()
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }else{
        // this.viewType = 1
      }
    },
    //兑换码
    handelExCode(){
      if(this.exCode == '') return this.$Message.error('请输入兑换码')
      let params = {
        exchange_code:this.exCode
      }
      exchange(params).then(res=>{
        if(res.code == 1){
          this.getUser()
          this.$Message.success(`${res.msg}`)
          this.pay_type = 'wx'
          this.this.exCode = ''
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })

    },
  },
};
</script>
<style lang='less' scoped>
@import '@assets/css/profile/shop/shop.less';
</style>