import request from "@utils/request";

//商城首页数据
export const getGoodsList = ()=>{
    return request({
        url:'index/goods/getGoodsList',
        method:'get'
    })
}
//商城支付弹窗Vip商品数据
export const getBuyVip = ()=>{
    return request({
        url:'index/shop/getBuyVip',
        method:'get',

    })
}
//商城支付弹窗空间和流量
export const getBuyRecharge =(params)=>{
    return request({
        url:'index/shop/getBuyRecharge',
        method:'get',
        params

    })

}
//兑换码
export const exchange=(params)=>{
    return request({
        url:'index/shop/exchange',
        method:'post',
        params
    })
}

//商城支付弹窗Vip商品支付二维码
export const sendBuyVip = (params)=>{
    return request({
        url:'index/shop/sendBuyVip',
        method:'post',
        params
    })
}
//商城支付弹窗空间或者流量支付二维码
export const sendBuyRecharge = (params)=>{
    return request({
        url:'index/shop/sendBuyRecharge?',
        method:'post',
        params
    })
}
//校验支付是否成功
export const checkOrderPay = (params)=>{
    return request({
        url:'index/shop/checkOrderPay',
        method:'post',
        params,
    })
}
